import React, { Fragment } from 'react';
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import moment from 'moment-timezone';
import Helmet from 'react-helmet'
import { rhythm } from "../utils/typography"
import { useQueryParam, StringParam } from "use-query-params"
import Layout from "../components/layout"
import gamePlayBtn from "../img/btn-play.png";
import gameEndBtn from "../img/cookinburger_end_EN2.png";
const $ = require("jquery")

const propTypes = {
  data: PropTypes.object.isRequired,
}

function itemSort() {
  let ctg = $('input[name="ctg"]:checked').val();
  let rank = $('input[name="rank"]:checked').val();
  let baseSelector = 'ul.l-nft-section-list li';
  let sortSelector = '';

  if( ctg == 'cart' ) {
    $('#rank-new').removeClass('disnon');
  } else {
    $('#rank-new').addClass('disnon');
  }

  if( ctg ) sortSelector = sortSelector+'.'+ctg;
  if( rank ) sortSelector = sortSelector+'.'+rank;
  let selected = $(baseSelector + sortSelector);
  $(baseSelector).not(sortSelector).addClass('disnon');
  selected.removeClass('disnon');
  $('#current').text(selected.length);
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

const News = ({ node }) => {
  const dateDisplay = node.displayDate.split('T')[0].replace(/-/g,'.');
  return (
    <div>
      <Link
        style={{ color: `#4E2714`, textDecoration: `none`, fontSize: "1.5rem", fontWeight: 600 }}
        to={`/${getLangDir(node.node_locale)}/news/${node.slug}/`}
      >
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            fontSize: 14,
            paddingBottom: rhythm(1),
            marginBottom: rhythm(1),
          }}
        >
          <div className="news-list-day">{dateDisplay}</div>
          <div className="news-list-title">{node.title}</div>
        </div>
      </Link>
    </div>
  )
}

const GameLink = () => {
  let gameLink = "https://game.cookinburger.com";
  const [rid, setRid] = useQueryParam('rid', StringParam);

  if (typeof window !== 'undefined') {
    if (rid === undefined && localStorage.getItem("rid") !== null) {
      gameLink = gameLink+"?rid="+localStorage.getItem('rid');
    } else {
      gameLink = rid !== undefined ? gameLink+"?rid="+rid : gameLink;
    }
  }
  return (
    <div className="btn-play"><img src={gameEndBtn} alt="Click to start!" /></div>
  )
}

function handleClick() {
  console.log('game link is clicked');
  if (typeof window !== 'undefined') {
    if (localStorage.getItem("rid") !== null) {
      var labelValue = '';
      var rId = '';
      var deferCampaignLog = new $.Deferred().resolve();
      deferCampaignLog.promise()
        .then(function() {
            rId = localStorage.getItem("rid");
            return rId
        })
        .then(function(rId) {
            if (rId === null) {
                rId = 'organic'
            }
            labelValue = rId+' | '+localStorage.getItem("cookinburger_guid_raw")+'-'+rId+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
            dataLayer.push({
                'event' : 'gaEvent',
                'eventCategory': 'Conversion: Campaign',
                'eventAction': 'click',
                'eventLabel': labelValue
            });
            var dataCampaignObj = {
                "Property": 'cookinburger',
                "Guid": localStorage.getItem("cookinburger_guid_raw")+'-'+rId,
                "Rid": rId,
                "Locale": window.pmApp.checkLocale(window.location.href),
                "Lp": window.location.href,
                "Reg": "0",
                "FT" : "0",
                "Conv": "1",
                "Username" : 'n/a',
                "Email": 'n/a',
                "Country": 'n/a',
                "City": 'n/a',
                "LonLat": 'n/a',
                "Ip": 'n/a',
                "Timezone": 'n/a',
                "Ref": localStorage.getItem("ref") !== null ? localStorage.getItem("ref") : 'n/a',
                "Lang": language
            };
            if (localStorage.getItem("game_conv_rid") === null) {
                window.pmApp.sendCampaignLog(dataCampaignObj);
            } else {
                var convDoneArray = localStorage.getItem("game_conv_rid");
                convDoneArray = convDoneArray.split(",");
                if (convDoneArray.indexOf(rId) === -1) {
                    window.pmApp.sendCampaignLog(dataCampaignObj);
                }
            }
        })
        .done(function() {
            return true;
        })
    } else {
        labelValue = 'organic | '+localStorage.getItem("cookinburger_guid_raw")+'-organic'+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
        dataLayer.push({
            'event' : 'gaEvent',
            'eventCategory': 'Conversion: Organic',
            'eventAction': 'click',
            'eventLabel': labelValue
        });
    }
  }
}

class IndexPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          loading: true,
          error: false,
          fetchedData: [],
          progress: false,
          dataAvail: false,
      };
    }

  componentDidMount() {
    const total = $('ul.l-nft-section-list li');
    $('#total').text(total.length);
    itemSort();

    $('input[name="ctg"]').on('change', function() {
      itemSort();
    });

    $('input[name="rank"]').on('change', function() {
      itemSort();
    });
  }

  shuffleArticle = (data, topNews) => {
    let articles = [], fixTopIndex = null;
    const numLength = topNews && topNews.length > 0 ? data.length - topNews.length : data.length;
    for (let i=0; i<numLength; i++) {
      if (data[i]['node']['positionTopFixed'] === null || data[i]['node']['positionTopFixed'] === false) {
        articles.push(data[i]);
      } else if (data[i]['node']['positionTopFixed'] == true) {
        fixTopIndex = i;
      }
    }
    return articles;
  }

  render() {
    const enTopNews = this.props.data.enTop.edges;
    const enNewsEdges = this.shuffleArticle(this.props.data.en.edges, enTopNews);
    const { loading, fetchedData, progress, dataAvail } = this.state;

    return (
      <Layout data={this.props.data} location={this.props.location} key={loading}>
        <Helmet>
          <title>Anyone can play! Multitasking cooking game - Cookin’ Burger</title>
          <meta name="description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />

          <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
          <meta property="og:title" content="I'm playing Cookin' Burger!" />
          <meta property="og:description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="I'm playing Cookin' Burger!" />
          <meta name="twitter:description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />
          <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
        </Helmet>
        <div className="l-main">

          <div className="l-key-visual">
            <div className="section-inner_m">
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-key-visual-logo wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img/logo-cb.png" alt="『Cookin’ Burger』" />
              </div>
              <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="700" data-sal-easing="ease"
                className="l-key-visual-title wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                <img className="l-key-visual-title-logo" src="../../assets/img/playmining-logo.png" alt="playmining" />
                <img src="../../assets/img/key-visual-title.png" alt="New PlayMining Platform Game Cookin' Burger" />
                <GameLink />
              </div>
              <div data-sal="slide-up" data-sal-delay="400" data-sal-duration="700" data-sal-easing="ease"
                className="l-key-visual-item wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img/key-visual-item.png" alt="" />
              </div>
            </div>
          </div>

          <div className="section-inner_m" style={{marginTop: "-24px"}}>
            <div className="l-news-section">
                <p className="l-about-section-cap">
                  Due to various circumstances, we have decided to close the service for 'Cookin' Burger' on Friday, January 31, 2025 at 12:00 (JST).
                  The schedule until the service closes is as follows.
                </p>

                <p>
                  <b>December 30th (Mon) 15:00 (JST)</b> - Start of Final Season<br /><br />
                  <b>January 27th (Mon) 12:00 (JST)</b> - End of Final Season, stop of DEAPcoin(DEP) emission, forced termination of scholarship function and  current contracts, closure of in-game shop<br /><br />
                  <b>January 31st (Fri) 12:00 (JST)</b> - End of service, access stopped<br /><br />
                  <b>February 3rd (Mon)</b> - Final Season salary distribution
                </p>

                <p style={{marginTop: "24px"}}>
                  Please note that Shop-NFT will continue to be available for secondary trading on PlayMining NFT after the service closes.
                  Finally, we would like to thank you for your love and support over the long period.
                </p>
            </div>
          </div>

          <div className="section-inner_m">
            <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
              className="l-news-section wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <ul className="news-list">
                  {enTopNews.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                  {enNewsEdges.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                </ul>
                <Link to={`/en/news/`}><div className="news-list-btn">More</div></Link>
            </div>
          </div>

          <div className="l-about-section">
            <div className="section-inner_m">

              <div className="l-about-section-box">
                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img/title-02.png" alt="What's Cookin’ Burger?" />
                </div>

                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  "Cookin' Burger" is a multi-tasking action game where you play the role of a burger shop worker, taking orders from different types of customers and serveing them accurately and quickly in order to build your shop's reputation.
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-ui wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img/ui.png" alt="" />
                </div>
                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                    The game simulates the management of a burger shop, which is familiar to people around the world. Despite its simple gameplay, the game requires players to learn the preferences of customers and to think about a more efficient cooking process,  just as if they were running an actual shop.
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-img wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img/about-section-img.png" alt="" />
                </div>

                <ul className="l-about-section-point">
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img/icon-01.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                            You can become a shop owner by buying Shop-NFT. There are various grades in shops from inexpensive food stalls with simple cooking processes, to large shops that are expensive and challenging to run but offer great return potentials. You can upgrade to a shop that suits your level of expertise.
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img/icon-02.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                            Shop-NFT has a parameter called "Reputation". By remembering customers' orders and preferences and serving them quickly, the number of regular customers increases, which in turn will increase repeat rate and leads to higher sales and reputation.<br />
                            On the other hand, by repeating mistakes in serving, poor grilling or failing to serve within the time limit, the shop's reputation will decline.<br />
                            <br />
                            In addition, each time you play the game, a parameter called "Endurance Value" will decrease, and when it reaches zero, you will no longer be able to play the game.
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img/icon-03.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                            NFT owners can hire part-time workers (scholars) to play the game for them. When the part-time workers (Scholars) improve their skills to handle more orders from customers and make the business more successful, they can be recruited on better terms by other shops.
                        </p>
                    </li>
                </ul>
              </div>

              <ul data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-footer-sns-list wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <li><a href="https://discord.gg/xWeHGdt" target="_blank"><img src="../../assets/img/sns-ico-dc.png" alt="" /></a></li>
                  <li><a href="https://twitter.com/PlayMining_SG" target="_blank"><img src="../../assets/img/sns-ico-x.png" alt="" /></a></li>
                  <li><a href="https://www.facebook.com/PlayMining/" target="_blank"><img src="../../assets/img/sns-ico-fb.png" alt="" /></a></li>
                  <li><a href="https://t.me/DEAPcoin_group" target="_blank"><img src="../../assets/img/sns-ico-tg.png" alt="" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCGWmK0RLV4SB_PSXpj2j6dw" target="_blank"><img src="../../assets/img/sns-ico-yt.png" alt="" /></a></li>
              </ul>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = propTypes

export default IndexPage

export const pageQuery = graphql`
  query PageEnUsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    en: allContentfulPost(filter: { node_locale: { eq: "en-US" } }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
    enTop: allContentfulPost(filter: { node_locale: { eq: "en-US" }, positionTopFixed: { eq: true} }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
  }
`
